.services-container {
    max-width: 960px;
    margin: 0 auto;
    text-align: center;
    padding: 50px 0;
  }

#services{
    background-color: #15C39A;
}

.slick-dots li button:before {
    font-size: 16px;
}

.slick-prev:before, .slick-next:before{
    font-size: 30px !important;
}
.service h3{
    font-size: 30px;
    color: #fff;
}
.txt_one{
    font-size: 36px;
    color: #fff;
}
.txt_two{
    padding: 50px;
    font-weight: bold;
    color: #fff;
}
.service{
    padding: 10px 0;
}

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}

.service-icon{
    color: #fff;
}

@media (max-width: 768px) {
    .txt_one{
        font-size: 30px;
    }
    .services-container{
        padding: 80px 0;
        width:70%;
    }
  }

  @media (min-width: 769px) and (max-width:980px) {
    .services-container{
        padding: 80px 0;
        width:90%;
    }
  }

.service p{
    font-size: 20px;
    color: #fff;
    /* text-align: left; */
}

.service-icon{
    font-size: 30px;
}
.slick-dots li.slick-active button:before{
    color: #fff;
}

.slick-dots{
    bottom: -10px;
}
