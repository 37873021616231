.contact-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    background-color: #f8f8f8;
    padding: 60px 0 10px 0;
  }
  
  .contact-form {
    text-align: center;
  }
  
  .contact-form h3 {
    font-size: 36px;
    font-weight: bold;
    margin: 10px 0 10px 0;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  textarea.form-control {
    resize: none;
  }
  
  form input, form textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin-bottom: 20px;
    font-family: BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
  
  form button {
    position:relative;
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

@media (max-width: 600px) {

  form input, form textarea {
    width: 80%;
  }
}
.logo-img{
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}