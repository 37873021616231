body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
}
html{
  scroll-behavior: smooth;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
header{
  position: fixed;
  width: 100%;
  z-index: 1;
}


main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  /* background-color: #333; */
  background-image: url("./bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.intro {
  max-width: 600px;
  color: #fff;
}

.intro h1 {
  margin-bottom: 10px;
  font-size: 60px;
  font-family: Arial, Helvetica, sans-serif !important;
}
.intro h1 span{
  color:#15C39A;
}

.intro h2 {
  font-size: 24px;
}
h2:nth-child(2){
  padding:10px !important;
}

footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-links a {
  color: #fff;
  margin: 0 10px;
  font-size: 24px;
}

.social-links a:hover {
  color: #ccc;
  cursor: pointer;
}
section#Main {
  width: 100%;
}
.about {
  padding: 50px 0;
}
.experience, .education{
  padding: 50px 0;
  background-color: #F5F5F5	;
}
.experience h2, .education h2{
  text-align: center;
  font-size: 36px;
  font-weight: bold;
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.about-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.about-text {
  width: 60%;
  max-width: 600px;
  /* margin-left: 40px; */
  /* text-align: justify; */
  padding-left: 1rem;
}

.about-text h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .about-text h2{
    text-align: center;
  }
  .about{
    padding: 100px 0;
  }
  .experience{
    padding: 80px 0;
  }
}
@media (max-width: 1168px){
  .vertical-timeline-element-content .vertical-timeline-element-date{
    color: #fff;
  }
}

.about-text p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 40px;
}
.personal-info {
  text-align: center;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  margin-left: 20px;
}

.personal-info h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.personal-info ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.personal-info li {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 10px;
}

.personal-info li span {
  font-weight: bold;
}

.skills {
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.skills h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.skills ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.skills li {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 10px;
}
.center{
  text-align: center;
}
.button_download {
  text-align: center;
}
.button {
  text-decoration: none;
  display: inline-block;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  background-color: transparent;
  color: #15C39A;
  border: 2px solid #15C39A;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
}
.button:hover{
  border: 2px solid lightblue;
  color: lightblue;
}

.download {
  display: inline-block;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  background-color: #333;
  border: 2px solid #333;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.download:hover {
  background-color: transparent;
  color: #15C39A;
  border: 2px solid #15C39A;
}
#bliss{
  color: white;
}
#bliss:hover{
  color: #333;
}
.vertical-timeline.vertical-timeline--animate.vertical-timeline--two-columns{
  position: relative;
}
.vertical-timeline-element-date{
  color:#333;
  font-weight: bold !important;
}
/* #resh2{
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
} */